<template>
  <div class="container">
    <div class="card border-0 shadow-none" v-if="!busy">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col">
            <h4 class="mb-0 text-success fw-bold">Email Templates</h4>
          </div>
          <div class="col-auto ms-auto">
            <router-link
              to="/email-templates/create"
              class="btn btn-sm btn-outline-success"
            >
              <i class="far fa-plus me-2"></i>Add New
            </router-link>
          </div>
        </div>

        <!--  -->

        <table class="table">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Last</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="cursor-pointer" v-for="t in templates" :key="t.id">
              <td @click="$router.push('/email-templates/' + t.id)">
                {{ t.title }}
              </td>
              <td @click="$router.push('/email-templates/' + t.id)">
                {{ t.created_at | formatDate }}
              </td>
              <td>
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteTemplate(t)"
                >
                  <i class="far fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <!--  -->
      </div>
    </div>

    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading.vue";
export default {
  data() {
    return {
      busy: false,
      templates: [],
    };
  },
  methods: {
    fetchTemplates() {
      this.busy = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/email-templates")
        .then(({ data }) => {
          this.templates = data;
          this.busy = false;
        });
    },
    deleteTemplate(template) {
      if (
        confirm(
          "Are you sure you wish to delete this template, this action cannot be undone?"
        )
      ) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL + "/email-templates/" + template.id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchTemplates();
          });
      }
    },
  },
  created() {
    this.fetchTemplates();
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
  components: { Loading },
};
</script>

<style></style>
